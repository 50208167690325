import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business.Commission.Item, State.Root> = {
  async CalculateFee(context, {
    currency = 'EUR',
    // eslint-disable-next-line camelcase
    fee_percent = 15.0, // #FEE
    unit_qty = 1, // eslint-disable-line camelcase
    unit_price, // eslint-disable-line camelcase
  }) {
    const res = await Api().post('/web-api/CalculateFee', {
      currency,
      fee_percent,
      unit_price,
      unit_qty,
    });

    return res.data.data;
  },
  async Create({ commit }, { BUSINESS_ID, COMMISSION_ID, form }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/items`, form,
    );

    commit('Push', res.data);

    return res.data.data;
  },
  async Find({ commit }, { BUSINESS_ID, COMMISSION_ID, ITEM_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/items/${ITEM_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async Forgive({ commit }, {
    BUSINESS_ID, COMMISSION_ID, ITEM_ID, form,
  }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/items/${ITEM_ID}/forgive`,
      form,
    );

    commit('Push', res.data);

    return res.data.data;
  },
  async List({ commit }, { BUSINESS_ID, COMMISSION_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/items`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
  async Refund({ commit }, {
    BUSINESS_ID, COMMISSION_ID, ITEM_ID, form,
  }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/items/${ITEM_ID}/refund`,
      form,
    );

    commit('Push', res.data);

    return res.data.data;
  },
};

export default actions;
