/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource, removeResourceById } from '@/util/list';

const mutations: MutationTree<State.Business.Commission.File> = {
  Remove(state, id: string) {
    removeResourceById(state.list, id);
  },
  Push(state, { data }: { data: ApiResource.CommissionFile }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.CommissionFile[] }) {
    data.forEach((m) => pushResource(state.list, m));
  },
};

export default mutations;
