/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Business.Commission.Trackers> = {
  Push(state, { data }: { data: ApiResource.ShippingTracker }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.ShippingTracker[] }) {
    data.forEach((t) => pushResource(state.list, t));
  },
};

export default mutations;
