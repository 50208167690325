import { ActionTree } from 'vuex';
import { State, UploadInstructions } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business.Commission.File, State.Root> = {
  async Confirm({ commit }, {
    BUSINESS_ID, COMMISSION_ID, FILE_ID, data,
  }) {
    const res = await Api().put(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files/${FILE_ID}`,
      data,
    );

    commit('Push', res.data);

    return res.data;
  },
  async Create({ commit }, { BUSINESS_ID, COMMISSION_ID, form }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files`,
      form,
    );

    commit('Push', res.data);

    return res.data;
  },
  async Delete(context, { BUSINESS_ID, COMMISSION_ID, FILE_ID }) {
    const res = await Api().delete(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files/${FILE_ID}`,
    );

    // TODO Unpush res.data;

    return res.data;
  },
  async Download(context, { BUSINESS_ID, COMMISSION_ID, FILE_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files/${FILE_ID}/download`,
    );

    return res.data;
  },
  async List({ commit }, { BUSINESS_ID, COMMISSION_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },

  async Find({ commit }, { BUSINESS_ID, COMMISSION_ID, FILE_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files/${FILE_ID}`);

    commit('Push', res.data);

    return res.data;
  },

  async Upload(context, { BUSINESS_ID, COMMISSION_ID, FILE_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files/${FILE_ID}`,
    );

    return res.data;
  },

  async UploadPipeInstructions(
    { rootGetters },
    { BUSINESS_ID, COMMISSION_ID, FILE_ID },
  ): Promise<UploadInstructions> {
    return {
      object: 'upload',
      file: 'FORM_DATA_APPEND_PARAM:File',
      method: 'POST',
      url: `${process.env.VUE_APP_API_URL}/businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/files/${FILE_ID}/upload`,
      headers: {
        Authorization: rootGetters['oauth2/AuthorizationHeader'],
      },
      params: {},
      query: null,
    };
  },
};

export default actions;
