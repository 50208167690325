import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business.Commission.Event, State.Root> = {
  async List({ commit }, { BUSINESS_ID, COMMISSION_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID}/events`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    // TODO: ForEach res.data.data(dispatch(find-that-thing))

    return res.data;
  },
};

export default actions;
