import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business.Commission.Trackers, State.Root> = {
  Find: (s) => (id: string): ApiResource.ShippingTracker | undefined => s.list.filter(
    (c) => c.id === id,
  )[0],
  List: (s) => (C: string): ApiResource.ShippingTracker[] => s.list.filter(
    (c) => c.commission_id === C,
  ),
};

export default getters;
